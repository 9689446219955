import React from "react";
import CSOB from "../components/CSOB";

export default function Beyond() {
  return (
    <CSOB.LayoutAnimationWrapper>
      <CSOB.Container>
        <CSOB.AnimationBox title="Vchod do COOP" button={{ text: "Otevřít", event: "coop" }} />
        <CSOB.AnimationBox
          title="Značka AUTOBUS"
          button={{ text: "Spustit animaci", event: "auto" }}
        />
        <CSOB.AnimationBox
          title="Značka PARKOVÁNÍ"
          button={{ text: "Spustit animaci", event: "park" }}
        />
        {/* <CSOB.Box>
        <CSOB.Title>Vchod do COOP</CSOB.Title>
        <CSOB.Container>
          <CSOB.Button>Otevřít</CSOB.Button>
        </CSOB.Container>
      </CSOB.Box>
      <CSOB.Box>
        <CSOB.Title>Značka AUTOBUS</CSOB.Title>
        <CSOB.Button>Otevřít</CSOB.Button>
      </CSOB.Box>
      <CSOB.Box>
        <CSOB.Title>Značka PARKOVÁNÍ</CSOB.Title>
        <CSOB.Button>Otevřít</CSOB.Button>
      </CSOB.Box> */}
      </CSOB.Container>
    </CSOB.LayoutAnimationWrapper>
  );
}
