import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { TangleMsgBox } from "lib/webcomponents/dialog-component";

const container = document.querySelector("#root") as HTMLElement;
const root = ReactDOM.createRoot(container);
root.render(<App />);

// ReactDOM.createRoot(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

TangleMsgBox.setStyles(`
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
    font-family: "Open Sans", sans-serif !important;
           font-weight: bold !important;

}

.tangle-msg-box-dialog-header {
  // color:red !important
}
.tangle-msg-box-dialog {
  background: #FFFFFF !important;
}
.tangle-msg-box-dialog-button {
  border-radius: 16px !important;
}
.tangle-msg-box-dialog-button:last-of-type {
    background: #FF6000 !important;
    color: white !important;
}
 .tangle-msg-box-dialog-button:last-of-type:hover {
    background: #FF6000 !important;
 }
 .tangle-msg-box-dialog-header {
       color: #003366 !important;
 }
 .tangle-msg-box-dialog-textbox {
  color: #FF6000 !important;
  background:white;
  outline:none;
  border: #FF6000 3px solid !important;
  border-radius: 16px !important;
  height: 18px !important;
      margin-top: 4px;
  text-transform:uppercase !important; 

 }
 .tangle-msg-box-dialog-body{
   padding-left:0px;
    padding-right:6px;
    padding-bottom:12px;  
 }
 .tangle-msg-box-dialog-footer{
   padding-bottom: 42px;
 }
 `);
