import React from "react";
import CSOB from "../components/CSOB";

// Tepající srdce
// - Spustit animaci
// Vlajka Ukrajiny
// - Spustit animaci

export default function Responsible() {
  return (
    <CSOB.LayoutAnimationWrapper>
      <CSOB.Container>
        <CSOB.MultiAnimationBox
          title="Tepající srdce"
          buttons={[
            { event: "s_tep", text: "Animace tepu" },
            { event: "s_ukr", text: "Ukrajinská vlajka" },
            { event: "s_vyp", text: "Vypnuto" },
          ]}
        />
      </CSOB.Container>
    </CSOB.LayoutAnimationWrapper>
  );
}
