import React from "react";
import CSOB from "../components/CSOB";

// Tablet
// - Spustit animaci

export default function Distribution() {
  return (
    <CSOB.LayoutAnimationWrapper>
      <CSOB.Container>
        <CSOB.AnimationBox title="Tablet" button={{ text: "Spustit animaci", event: "table" }} />
      </CSOB.Container>
    </CSOB.LayoutAnimationWrapper>
  );
}
