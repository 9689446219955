import Header from "layout/Header";
import React from "react";
import CSOB from "../components/CSOB";

export default function Home() {
  return (
    <CSOB.HomeLayoutAnimationWrapper>
      <CSOB.Box>
        <CSOB.Title>Vyberte svůj pavilon</CSOB.Title>
        <CSOB.Container>
          {/* <CSOB.Link to="kate">Kate World</CSOB.Link> */}
          <CSOB.Link to="beyond">Beyond Banking</CSOB.Link>
          <CSOB.Link to="distribution">One distribution</CSOB.Link>
          <CSOB.Link to="responsible">Responsible behaviour</CSOB.Link>
        </CSOB.Container>
      </CSOB.Box>
    </CSOB.HomeLayoutAnimationWrapper>
  );
}
