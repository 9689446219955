import create from "zustand";
import { io } from "socket.io-client";
import { TangleMsgBox } from "lib/webcomponents/dialog-component";

const socket = io("https://tiktok.host.tangle.cz/");

export const useCSOBStore = create((set: any) => ({
  connected: false,
  blocklyConnected: false,
  animations: {
    test: false,
  },
  setAnimation: (event: string, value: any) =>
    set((state: any) => {
      console.log({ event, value });
      // TODO
      // Do not change anything from the original state, wait for response from Blockly
      socket.emit("event", { event, value }, () => {
        console.log("Animation set");
      });

      // return {
      //   ...state,
      //   animations: {
      //     ...state.animations,
      //     [event]: value,
      //   },
      // };
    }),
}));

socket.on("connect", () => useCSOBStore.setState({ connected: true }));
socket.on("disconnect", () => useCSOBStore.setState({ connected: false }));
socket.on("blockly_connection", (status) => {
  useCSOBStore.setState({ blocklyConnected: status });
  console.log({ status });
});

socket.on("event_callback", ({ event, value }) => {
  console.log("event_callback", { event, value });
  const animations = useCSOBStore.getState().animations;

  useCSOBStore.setState({
    animations: {
      ...animations,
      [event]: value,
    },
  });
});

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

const witherrors = urlParams.get("witherrors");
if (witherrors) {
  socket.on("event_error_callback", ({ err, event, emitValue }) => {
    // console.log("event_error_callback", error);

    TangleMsgBox.alert(JSON.stringify({ event, emitValue }), err);
  });
}

socket.on("events", (eventsdata) => {
  console.log({ eventsdata });
  useCSOBStore.setState({
    animations: eventsdata,
  });
});
